.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  height: 100vh;
  font-family: Arial, sans-serif;

  .contact-introduction-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;

    @media screen and (max-width: 700px) {
      max-width: 80%;
    }
  }

  .contact-introduction {
    // font-family: "new-kansas", sans-serif;
    // font-weight: 400;
    // text-align: left;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #222;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    #message {
      height: 60px;
    }
  }
  @media screen and (max-width: 700px) {
    form {
      height: 50vh; 
      width: 80%;
    }
  }
  
  label {
    margin-bottom: 5px;
  }

  input,
  textarea {
    margin-top: 10px;
    width: calc(100% - 50px); 
    padding: 10px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #333;
    color: white;
    font-size: 16px;

    &:focus {
      outline: 2px solid #555;
      background-color: #444;
    }
    #name {
      width: 50px;
    }
  }

  button {
    padding: 10px;
    width: inherit;
    background-color: #000000;
    color: white;
    border: solid 1px rgb(0, 0, 0);
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;

    &:hover {
      cursor: pointer;
      background-color: white;
      color: black;
      border: solid 1px black;
    }
  }
}
