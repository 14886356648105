.content-box {
  padding: 15px; 
  border-style: solid;
  border-width: 1.5px;
  border-color: black;
  border-radius: 20px;
  backdrop-filter: blur(20px);
  position: relative;
  overflow: hidden; 
  height: 300px;
  width: 300px;
  transform-style: preserve-3d;

  @media screen and (min-width: 700px) {
    height: 500px;
    width: 300px;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    border: solid 1px black;

  }
  h1 {
    font-family: "new-kansas", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  p {
    font-size: 16px; 
    margin-top: 5px; 
  }
}

.content-title {
  width: 100%; 
  height: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}