.menu-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .who-am-i, .my-services, .cool-projects {
    margin: 0 auto;
  }
}

// Cool Projects section

.cool-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px; 
}

.cool-projects-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coolproject-partners-sections {
  position: relative;
  top: -50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.coolproject-partners {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.coolproject-partners img {
  height: 60px;
  cursor: pointer;
  transition: scale 0.3s ease;

  &:hover {
    scale: 1.05; 
  }

  @media screen and (min-width: 650px) {
    height: 100px;
  }
}

// My Services Section

.my-services {
  h2 {
    padding: 20px 0;
  }
}

.my-services-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;


  @media screen and (min-width: 736px) {
    flex-direction: row;
  }
}

// Who Am I Section
.text-content-container {
  width: 85vw;
  max-width: 1000px;
  padding: 20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.text-content {
  text-align: left;
}

.text-content-special {
  font-family: "new-kansas", sans-serif;
  font-weight: 400;
  font-style: italic;
}
