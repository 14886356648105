
h1 {
  font-family: "new-kansas", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 56px;
  margin: 0; 
}

h2 {
  font-family: "new-kansas", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  margin: 0;
}

p {
  font-family: "area-normal", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  margin: 0;
}

.contentText {
  width: 100vw;
  max-width: 1000px;
  /* width: 60vw; */
  display: flex; 
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1;
  margin-bottom: 20px;
}

.contentText-hireMe {
  min-height: 150px;
}

.contentText-other {
  min-height: 120px;
  @media screen and (min-width: 768px) {
    min-height: 0px;
  }
}

.word {
  display: inline-block;
}

/* Initial loading of text */ 
.char {
  opacity: 0;                
  animation: appear 0.01s ease-in;  
  animation-fill-mode: forwards; 
  display: inline-block;      
}

@keyframes appear {          
  to {
    opacity: 1;               
  }
}

/* Loading of body text */
.hidden {
  opacity: 0; 
  transition: opacity 0.8s ease-in; 
}

.visible {
  opacity: 1; 
  transition: opacity 0.8s ease-in; 
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 32px;
  }
  p {
    font-size: 16px;
  }
  .contentText {
    width: 85vw;
  }
}
