.scroll-container {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAElBMVEUAAACkpqScmpycnpykoqSUlpQeVBQmAAAAAXRSTlMAQObYZgAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAvFJREFUOI0Vk4sBRREMQ61wfQbwG4DWAFQHoOy/yvMGkMpJopzc04T9xH7WbB+mlS/foLRJy+HhjngqWZBw9tzZKwi+sz7V6O8Ssd2YxY4P1dCSmgeqFN0M/MWZ18VcVdMU6ih2rBgGhu3YWRNdVGCZnPXZ6crdnqaBlo60FC+Y1/uvWb92KYE4ad/hKjQ6CxhMbO3VG8vE5pg/9Qm6UQvO7G2ZMMPp6XhrFZAAn2/Tt1yBRXRhNPFF6Y97gaq7j9iyvWiT1uZ2RccRV9gQRVYcJmwJ3j2DaegM+t0EMq3iqi4yr3cjj6bxkQy9y27DUNEXv9NUhDbIjipTh/zhkxm3jalVD+AS9Tiw7pKY6KlV2knJbBfB2oKCKxSt17p5kldNIsufe4mye8AbU4MOqCZN/O5z8lKyBorxD56FpEw3jmX4XPn/qA2Y4httVRtEQ5jyR89XagHjFFOjskQgxua7gJ/YHLl2ismo5jXTuWmV4EMfHIU3mskqmp6ckb5rxmJWPjy1GUVUatPx0TR3WnamNaeJuu6s3Kb0wjC2xXZCFPhIdzuMetlJq6cU9u6mw1AISg1b9ZtymaltcqkWyHr4pJ9NNTVjRfF+YRn1mu+wxOCjGnufl+0Fdy22x+aVJMtwygRr7hhfscVQc242fx1E96SqeRAoXVjXNVc3upVBFIuz2y2cJz9Q8whg0B66KrC3f9QGr3vTNie++x2DSmTKWcYscw68xIL1L7tlVfXjmm3lJOCF+CHI2SdF1bCXVuU++KVx1xXeanz9FDwIHGOK16Wc4qJmzWkTHnaapy3M93yfcI/Rj7G+qfp8i63AjhHMdEY3pni/5wOGLbQ5vcZXeBqkQUYQRbv6bzsoEZDyfVU+3/0gq2RKrZntAJ1Z6r3bc93iVK2p34J2SH/AgG0vX8VXuN3fVz8o47z6eoBJEi/xUEd0esvtNGndc/2bH2ucU4W2ob9O7xxO7ALDrZ1O24rtLCGhd9rRpZSc32aPij+i8OnKGHRalQAAAABJRU5ErkJggg==), linear-gradient(180deg, rgb(68, 98, 255) 10%, #FC6C3F 90%);
  background-blend-mode: overlay;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.scroll-section {
  position: relative;
  width: 100vw;
  height: 150vh;
  // min-height: 800px;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 650px) {
    border: none;
  }
}

.first-section {
  max-height: calc(100vh - 55px*3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-section {
  min-height: 1000px;
}

.content-wrapper,
.menu,
.contact-form-wrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.content-wrapper.visible,
.menu.visible,
.contact-form-wrapper.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.introductionText {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
}

.scroll-section .MenuContent {
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 650px) {
  .scroll-section .first.cloudImage {
    width: 150px; 
  }
  .scroll-section .second.cloudImage_1 {
    width: 80px;
  }
}