.carousel-container {
    overflow: hidden;
    max-width: 100vw;
    height: 400px;
  }
  
  .carousel-scroller {
    display: flex;
    align-items: start;
    height: 100%;
    width: max-content;
  }
  
  .scroller-content {
    display: flex;
  }
  
  .carousel-item {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 350px;
    margin: 0 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  @media screen and (min-width: 648px) {
    .carousel-container {
      height: 600px;
    }
    .carousel-item {
      width: 700px;
      height: 500px;
    }
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: top;
    border-radius: 8px;
    box-shadow: 10px 5px 5px black;
  }