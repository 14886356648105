$menu-height: 100vh;
$menu-height-mobile: 95vh; 
$menu-item-height: 55px;
$menu-item-height-mobile: 40px; 
$menu-item-border-width: 2px;
$transition-time: 1s; 

// Referring to menu as a whole
.menu {
  padding: 0;
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 100;

  &.visible {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
  }
}

.menu-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.menu-item {
  margin-top: -2px;
  box-sizing: border-box; 
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center; 
  font-family: "area-normal", sans-serif;
  font-weight: 600;
  font-style: normal;
  transition: border-width 3s ease, margin-top 1s ease, margin-bottom 1s ease;
  backdrop-filter: blur(10px);
  &:hover {
    box-shadow: 0 8px 10px rgba(1, 1, 1, 0.2);
  }
}
@media (min-width: 700px) {
  .menu-item {
    border-style: solid;
    //border-width: $menu-item-border-width;
  }
  .menu-item-1, .menu-item-2, .menu-item-3 {
    transition: transform $transition-time ease, background-color 0.5s ease, box-shadow 0.5s ease;

    &.menu-1-active {
      //background-color: rgb(255, 205, 255);
      //transform: translateY(-100%);
      box-shadow: 0 8px 10px rgba(1, 1, 1, 0.2);
    }

    &.menu-2-active, &.menu-3-active {
      $translateY: calc(-#{$menu-height} + #{3*$menu-item-height - 4px});
      transform: translateY($translateY);
      //background-color: rgb(255, 205, 255);
      box-shadow: 0 8px 10px rgba(1, 1, 1, 0.2);
    }
  }
}


.menu-item-1 { 
  transition: transform $transition-time ease, background-color 0.5s ease, box-shadow 0.5s ease;
  &:hover {
    background-color: rgb(255, 205, 255);
  }

}

.menu-item-2 {
  transition: transform $transition-time ease, background-color 0.5s ease, box-shadow 0.5s ease; 
  background: inherit;
  &:hover {
    background-color: rgb(255, 205, 255);
  }
}

.menu-item-3 {
  transition: transform $transition-time ease, background-color 0.5s ease, box-shadow 0.5s ease; 
  &:hover {
    background-color: rgb(255, 205, 255);
  }
}

.menu-item:hover {
  cursor: pointer;
  z-index: 5; 
  font-weight: 700; 
  opacity: 0.95; 
  box-shadow: 0 8px 10px rgba(1, 1, 1, 0.2);
}